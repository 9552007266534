<template>
    <div class="container-box">
        <div class="steps-wrapper">
            <div class="step-item current">
                <div class="step-line"></div>
                <div class="step-text">1</div>
                <div class="step-title">创建直播销售数据</div>
            </div>
            <div class="step-item">
                <div class="step-line"></div>
                <div class="step-text">2</div>
                <div class="step-title">添加直播销售数据</div>
            </div>
            <div class="step-item">
                <div class="step-line"></div>
                <div class="step-text">3</div>
                <div class="step-title">创建成功</div>
            </div>
        </div>
        <div class="content">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
                <el-form-item label="创建名称" prop="name">
                    <el-input class="onstep-inpue" placeholder="请输入名称" v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="行业分类" prop="category">
                    <el-select class="onstep-select" v-model="ruleForm.category" placeholder="请选择分类">
                        <el-option v-for="cateItem in categoryList" :key="cateItem.id" :label="cateItem.name" :value="cateItem.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div style="margin-bottom: 10px">商品介绍文档</div>
            <el-upload
                class="upload-demo"
                multiple
                :limit="2"
                :data="{type: 1}"
                accept=".doc, .docx, .zip, .rar"
                :action="uploadCover"
                :on-change="handleChangePic"
                :file-list="introduce_file_fileList"
                :headers="headersParams"
                :on-success="(value)=> handleAvatarSuccess('introduce_file', value)"
                :before-upload="beforeAvatarUpload">
                <div class="uoload-box">
                    <el-button size="small" class="btn-blue">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">(文件支持格式为doc、docx、zip和rar，文件大小不能超过10M)</div>
                </div>
            </el-upload>
            <div style="margin-bottom: 10px; margin-top: 40px">人物设定文档</div>
            <el-upload
                class="upload-demo"
                multiple
                :limit="2"
                :data="{type: 1}"
                accept=".doc, .docx, .zip, .rar"
                :action="uploadCover"
                :on-change="handleChangePic"
                :file-list="person_file_fileList"
                :headers="headersParams"
                :on-success="(value)=> handleAvatarSuccess('person_file', value)"
                :before-upload="beforeAvatarUpload">
                <div class="uoload-box">
                    <el-button size="small" class="btn-blue">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">(文件支持格式为doc、docx、zip和rar，文件大小不能超过10M)</div>
                </div>
            </el-upload>
            <div style="margin-bottom: 10px; margin-top: 40px">贴片素材</div>
            <el-upload
                class="upload-demo"
                multiple
                :limit="2"
                :data="{type: 1}"
                accept=".zip, .rar"
                :action="uploadCover"
                :on-change="handleChangePic"
                :file-list="material_file_fileList"
                :headers="headersParams"
                :on-success="(value)=> handleAvatarSuccess('material_file', value)"
                :before-upload="beforeAvatarUpload1">
                <div class="uoload-box">
                    <el-button size="small" class="btn-blue">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">(文件支持格式为zip和rar，文件大小不能超过10M)</div>
                </div>
            </el-upload>
        </div>
        <div class="bottom-btn">
            <el-button @click="prevBtn()" class="btn-blue-line-all">返回</el-button>
            <el-button class="btn-blue" @click="nextBtn('ruleForm')">下一步</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "firstStep",
    data() {
        return {
            ruleForm: {
                type: 1,
                name: '',
                id: 0,
                category: '',
                introduce_file: {
                    url: "",
                    name: ""
                }, //商品介绍文档
                person_file: {
                    url: "",
                    name: ""
                }, //人物设定文档
                material_file: {
                    url: "",
                    name: ""
                } //贴片素材
            },
            rules: {
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                    { min: 2, max: 18, message: '名称长度为2-18个字符', trigger: 'blur' }
                ],
                category: [
                    { required: true, message: '请选择分类', trigger: 'change' }
                ],
            },
            uploadCover: this.$api.live_saleUpload,
            headersParams: {
                Authorization: localStorage.getItem("token"),
            },
            categoryList: [],
            introduce_file_fileList: [],
            person_file_fileList: [],
            material_file_fileList: []
        };
    },
    created() {
        this.getCategoryList()
        if (this.$route.query.id) {
            this.getLive_saleInfo()
        }
    },
    methods: {
        handleChangePic(file,fileList) {
            if (fileList.length > 1) {
                fileList.splice(0, 1);
            }
        },
        getLive_saleInfo() {
            let params = {
                type: '1',
                id: this.$route.query.id
            }
            this.$http.axiosGetBy(this.$api.live_saleInfo, params, (res) => {
                if (res.code == 200) {
                    this.ruleForm = res.data
                    this.introduce_file_fileList.push(res.data.introduce_file)
                    this.person_file_fileList.push(res.data.person_file)
                    this.material_file_fileList.push(res.data.material_file)
                }
            }, (err) => {
                console.log(err)
            })
        },
        prevBtn() {
            sessionStorage.removeItem('liveOneStepToken')
            this.$router.push('/admin/liveMarket')
        },
        nextBtn(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.ruleForm.introduce_file.url == '') {
                        return this.$message.error('请上传商品介绍文档')
                    }
                    if (this.ruleForm.person_file.url == '') {
                        return this.$message.error('请上传人物设定文档')
                    }
                    if (this.ruleForm.material_file.url == '') {
                        return this.$message.error('请上传贴片素材')
                    }
                    this.$http.axiosPost(this.$api.live_saleOperate, this.ruleForm, (res) => {
                        if (res.code === 200) {
                            sessionStorage.setItem('liveOneStepToken', res.data.token)
                            this.$emit("stepActiveChange", 2);
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }
            });
        },
        handleAvatarSuccess(type, res) {
            let typeFlag = {
                'introduce_file': () => {
                    this.ruleForm.introduce_file.url = res.data.url
                    this.ruleForm.introduce_file.name = res.data.name
                },
                'person_file': () => {
                    this.ruleForm.person_file.url = res.data.url
                    this.ruleForm.person_file.name = res.data.name
                },
                'material_file': () => {
                    this.ruleForm.material_file.url = res.data.url
                    this.ruleForm.material_file.name = res.data.name
                },
            }
            if (res.code === 200) {
                typeFlag[type]()
                this.$message.success(res.msg);
            } else {
                this.$message.error("图片上传失败，请稍后再试~");
            }
        },
        beforeAvatarUpload(rawFile) {
            var testmsg = rawFile.name.substring(rawFile.name.lastIndexOf('.')+1)
            const extension = ['doc', 'docx', 'zip', 'rar'].includes(testmsg)
            const isLt2M = rawFile.size / 1024 / 1024 < 10     //这里做文件大小限制
            if(!extension) {
                this.$message({
                    message: '文件支持格式为doc、docx、zip和rar',
                    type: 'warning'
                });
            }
            if(!isLt2M) {
                this.$message({
                    message: '上传文件大小不能超过 10MB!',
                    type: 'warning'
                });
            }
            return extension && isLt2M
        },
        beforeAvatarUpload1(rawFile) {
            var testmsg = rawFile.name.substring(rawFile.name.lastIndexOf('.')+1)
            const extension = ['zip', 'rar'].includes(testmsg)
            const isLt2M = rawFile.size / 1024 / 1024 < 10     //这里做文件大小限制
            if(!extension) {
                this.$message({
                    message: '文件支持格式为zip和rar',
                    type: 'warning'
                });
            }
            if(!isLt2M) {
                this.$message({
                    message: '上传文件大小不能超过 10MB!',
                    type: 'warning'
                });
            }
            return extension && isLt2M
        },
        // 获取行业分类列表
        getCategoryList() {
            this.$http.axiosGet(this.$api.categoryList, (res) => {
                if (res.code === 200) {
                    this.categoryList = res.data
                } else {
                    this.$message.warning(res.msg)
                }
            }, (err) => {
                console.log(err)
            })
        },
    }
};
</script>

<style scoped lang="scss">
.container-box {
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .content {
        flex: 1;
        padding-top: 30px;
        padding-left: 100px;
    }
    .bottom-btn {
        text-align: center;
    }
}
.onstep-inpue {
    width: 500px;
}
.onstep-select {
    width: 500px;
}
.uoload-box {
    display: flex;
    flex-direction: row;
    .el-upload__tip {
        margin-left: 10px;
    }
}
.upload-demo {
    width: 500px;
}
//样式，去掉过渡动画
/deep/ .el-list-enter-active,
/deep/ .el-list-leave-active {
    transition: none;
}
/deep/ .el-upload-list {
    height: 40px;
}
</style>
