<template>
    <div style="height: 100%;padding-top: 20px">
        <div class="steps-wrapper">
            <div class="step-item">
                <div class="step-line"></div>
                <div class="step-text">1</div>
                <div class="step-title">创建直播销售数据</div>
            </div>
            <div class="step-item current">
                <div class="step-line"></div>
                <div class="step-text">2</div>
                <div class="step-title">添加直播销售数据</div>
            </div>
            <div class="step-item">
                <div class="step-line"></div>
                <div class="step-text">3</div>
                <div class="step-title">创建成功</div>
            </div>
        </div>
        <div class="create-content">
            <div class="success-content">
                <i class="iconfont">&#xe730;</i>
                <div class="text">创建成功！</div>
            </div>
            <el-button @click="prevBtn()" class="btn-blue-line-all">返回</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "threeStep",
    methods: {
        prevBtn() {
            this.$router.push("/admin/liveMarket");
            sessionStorage.removeItem('liveOneStepToken')
        },
    }
}
</script>

<style scoped lang="scss">
.create-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .success-content {
        margin-top: 155px;
        text-align: center;
        display: flex;
        justify-content: center;
        .iconfont {
            color: #1fd18c;
            font-size: 52px;
        }
        .text {
            font-size: 24px;
            font-weight: bold;
            display: flex;
            align-items: center;
        }
    }
    .btn-blue-line-all {
        margin-top: 50px;
    }
}
</style>